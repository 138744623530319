import { default as aboutfjC3jDeEhWMeta } from "/home/fred/Development/kasteel-website/app/pages/about.vue?macro=true";
import { default as contact_45usYYNOOmE9SfMeta } from "/home/fred/Development/kasteel-website/app/pages/contact-us.vue?macro=true";
import { default as drinkszVhfJPEHB8Meta } from "/home/fred/Development/kasteel-website/app/pages/drinks.vue?macro=true";
import { default as foodWejZC8XFaKMeta } from "/home/fred/Development/kasteel-website/app/pages/food.vue?macro=true";
import { default as gallerydxLZi7Npv3Meta } from "/home/fred/Development/kasteel-website/app/pages/gallery.vue?macro=true";
import { default as index3sCcAFAWVqMeta } from "/home/fred/Development/kasteel-website/app/pages/index.vue?macro=true";
import { default as our_45coffeeX9pYT0QKR1Meta } from "/home/fred/Development/kasteel-website/app/pages/our-coffee.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/fred/Development/kasteel-website/app/pages/about.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/home/fred/Development/kasteel-website/app/pages/contact-us.vue")
  },
  {
    name: "drinks",
    path: "/drinks",
    component: () => import("/home/fred/Development/kasteel-website/app/pages/drinks.vue")
  },
  {
    name: "food",
    path: "/food",
    component: () => import("/home/fred/Development/kasteel-website/app/pages/food.vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    component: () => import("/home/fred/Development/kasteel-website/app/pages/gallery.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/fred/Development/kasteel-website/app/pages/index.vue")
  },
  {
    name: "our-coffee",
    path: "/our-coffee",
    component: () => import("/home/fred/Development/kasteel-website/app/pages/our-coffee.vue")
  }
]